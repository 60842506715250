import { render, staticRenderFns } from "./App.vue?vue&type=template&id=5250ffc2&scoped=true"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
import style0 from "./App.vue?vue&type=style&index=0&id=5250ffc2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5250ffc2",
  null
  
)

export default component.exports
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QNoSsr from 'quasar/src/components/no-ssr/QNoSsr.js';
import QResizeObserver from 'quasar/src/components/resize-observer/QResizeObserver.js';
import qInstall from "../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QScrollArea,QNoSsr,QResizeObserver});
